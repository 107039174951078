import React, { useMemo } from "react";
import { TailSpin } from "react-loader-spinner";
import { IChats } from "types/ChatTypes";
import { IUserInfo } from "types/UserTypes";
import Messages from "./Messages";

import * as C from "../styles";

interface NewActiveChatProps {
  activeChat: IChats;
  user: {
    id: number;
  };
  scrollToBottom: () => void;
}

const ActiveChat = ({ activeChat, user, scrollToBottom }: NewActiveChatProps) => {
  return !activeChat ? (
    <C.LoaderContainer>
      <TailSpin color="#02a499" height={50} width={50} />
    </C.LoaderContainer>
  ) : (
    <div className="message">
      <Messages
        creatorId={activeChat?.creator?.id ?? activeChat.creatorId  }
        scrollToBottom={scrollToBottom}
        userId={user.id}
        messages={activeChat.messages}
      />
    </div>
  );
};

export default ActiveChat;
