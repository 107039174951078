import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { IChats, IVideoCallHistory, JoinChat } from "types/ChatTypes";
import { baseQuery } from "./chatSettings";

export const chatAPI = createApi({
  reducerPath: "chatAPI",
  tagTypes: ["chats", "chat"],
  baseQuery,
  endpoints: (build) => ({
    getChats: build.query<{data: IChats[]}, number | void>({
      query: (page = 1) => ({
        url: `/chats?page=${page}&size=20&latestMessageOnly=true`,
        method: "GET",
      }),
      providesTags: () => ["chats"],
    }),
    sendMessage: build.mutation<{ data: any }, FormData>({
      query: (body) => ({
        url: `/sendMessage`,
        method: "POST",
        body,
      }),
      invalidatesTags: () => ["chats"],
    }),
    joinToChat: build.mutation<{ data: any }, JoinChat>({
      query: (body) => ({
        url: `/chats/join`,
        method: "POST",
        body,
      }),
      invalidatesTags: () => ["chats"],
    }),
    readMessage: build.mutation<{ data: any }, { participantId: number; messageId: string }>({
      query: (body) => ({
        url: `/chats/read`,
        method: "POST",
        body,
      }),
      invalidatesTags: () => ["chats"],
    }),
    getChat: build.mutation<IChats, string>({
      query: (id) => ({
        url: `/chats/${id}`,
        method: 'GET'
      })
    })
  }),
});
