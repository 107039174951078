import React, { useState, useEffect, useRef, useCallback } from "react";
import { chatAPI } from "../../../../services/chatService";
import InputChat from "./IputChat";
import NewChatItem from "./New/ChatItem";
import OldChatItem from "./Old/ChatItem";
import useNewChatService from "hooks/useNewChatService";
import ActiveChat from "./New/ActiveChat";
import OldActiveChat from "./Old/ActiveChat";

import * as C from "./styles";
import useOldChatService from "hooks/useOldChatService";
import { IChats } from "types/ChatTypes";
import audioMessage from "../../../../assets/sounds/chatMessage.mp3";
import { toast } from "react-toastify";
import Loader from "components/Common/Loader";
import HeaderUserChat from "./HeaderUserChat";
import InfoCars from "./InfoCars";

const MobileChat = () => {
  const user = React.useMemo(() => {
    return JSON.parse(localStorage.getItem("user") as string);
  }, []);
  const [userImage, setUserImage] = useState("");
  const [userName, setUsername] = useState("Admin");
  const activeChatRef = React.useRef<HTMLDivElement | null>(null);

  const [value, setValue] = useState<string>("");
  const [files, setFiles] = useState<any>([]);
  const [activeVersionChat, setActiveVersionChat] = useState<null | string>(
    null
  );

  const [isShowListChats, setIsShowListChats] = useState<boolean>(true);
  const [isShowCarInfo, setIsShowCarInfo] = useState<boolean>(false);

  const sound = new Audio(audioMessage);
  const [sendMessage] = chatAPI.useSendMessageMutation();

  const {
    activeChat,
    userInfo,
    cars,
    selectChat,
    chatsLoading,
    chats,
  } = useNewChatService({
    scrollToBottom,
    user,
    showToast,
  });

  console.log("cars", cars);

  const commonChats = React.useMemo(() => {
    let chatsList: any = [];

    chats?.map((item) => {
      chatsList.unshift({
        ...item,
        isNew: true,
        createdAt: item.updatedAt,
      });
    });

    chatsList.sort((a, b) => {
      return new Date(b.createdAt as string) - new Date(a.createdAt as string);
    });

    console.log(
      "SORTED",
      new Date(chatsList?.[0]?.createdAt),
      new Date(chatsList?.[1]?.createdAt),
      chatsList?.[0]?.createdAt,
      chatsList?.[1]?.createdAt
    );
    return chatsList;
  }, [chats]);

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 400);
  }, [commonChats]);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("user") || "");
        setUsername(obj.initials);
        setUserImage(obj.avatar);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("user") || "");
        setUsername(obj.initials);
        setUserImage(obj.avatar);
      }
    }
    return () => {
      document.body.style.setProperty("--overflow-body", "auto");
    };
  }, []);

  function showToast() {
    toast.info("Пришло сообщение в чат");
    sound.play();
  }

  function scrollToBottom() {
    if (activeChatRef.current) {
      activeChatRef.current.scrollTop = activeChatRef.current.scrollHeight;
    }
  }

  const handleChange = (target: any) => {
    setValue(target.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (files.length > 0 || value !== "") {
      const formData = new FormData();

      formData.append("from", user.id);
      formData.append("to", activeChat?.id as string);
      formData.append("image", files[0]);
      formData.append("message", value);

      sendMessage(formData);
    }
  };

  const handleFileChange = (e: any) => {
    e.target.files.length > 0 && setFiles(e.target.files);
  };

  useEffect(() => {
    document.body.style.setProperty(
      "--overflow-body",
      !isShowListChats ? "hidden" : "auto"
    );
  }, [isShowListChats]);

  if (commonChats === undefined || userInfo === undefined) return <Loader />;
  return (
    <div className="page-content">
      {
        <C.Container>
          <C.PreviewChatsContainer>
            {isShowListChats || activeVersionChat == null ? (
              <C.PreviewChatsListContainer>
                <div className="chats-mobile">
                  {commonChats?.map(
                    (item, index) =>
                      item.messages.length !== 0 && (
                        <NewChatItem
                          selectChat={(item) => {
                            setActiveVersionChat("new");
                            selectChat(item);
                            setIsShowListChats(false);
                          }}
                          item={item}
                          key={index}
                          user={user}
                        />
                      )
                  )}
                </div>
              </C.PreviewChatsListContainer>
            ) : (
              <C.ChatViewContainer>
                {activeVersionChat && (
                  <HeaderUserChat
                    setIsShowListChats={setIsShowListChats}
                    setIsShowCarInfo={setIsShowCarInfo}
                    isShowCarInfo={isShowCarInfo}
                    isShowListChats={isShowListChats}
                    activeChat={activeChat}
                    userInfo={userInfo}
                    chatsFetching={chatsLoading}
                  />
                )}
                {activeVersionChat && !isShowCarInfo && !chatsLoading && (
                  <C.MessagesContainer>
                    <div className="messages" ref={activeChatRef}>
                      <ActiveChat
                        activeChat={activeChat as IChats}
                        user={user}
                        scrollToBottom={scrollToBottom}
                      />
                    </div>
                    <InputChat
                      value={value}
                      setValue={setValue}
                      files={files}
                      setFiles={setFiles}
                      handleSubmit={handleSubmit}
                      handleFileChange={handleFileChange}
                      handleChange={handleChange}
                    />
                  </C.MessagesContainer>
                )}
              </C.ChatViewContainer>
            )}
          </C.PreviewChatsContainer>
        </C.Container>
      }
      {chatsLoading && <Loader />}
      {(activeVersionChat === "new" || activeVersionChat === "old") &&
        isShowCarInfo && (
          <InfoCars activeVersionChat={activeVersionChat} cars={cars} />
        )}
    </div>
  );
};

export default MobileChat;
