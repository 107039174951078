import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { chatAPI, userAPI } from "services";
import { io } from "socket.io-client";
import { IChats } from "types/ChatTypes";
import { IUserInfo } from "types/UserTypes";

interface NewChatServiceHookProps {
    scrollToBottom: () => void;
    user: {
        email: string;
        avatar: string;
        initials: string;
        id: string;
    };
    showToast: () => void;
}

const useNewChatService = ({ scrollToBottom, user, showToast}: NewChatServiceHookProps) => {
    const socket = React.useRef<any>(null);
    
    const [getChats, {isFetching: chatsFetching, isLoading: chatsLoading}] = chatAPI.useLazyGetChatsQuery();
    const [getUser, {isFetching: userIsFetching}] = userAPI.useLazyGetUserQuery();
    const [getUserInfo] = userAPI.useLazyGetUserQuery();
    const [joinToChat] = chatAPI.useJoinToChatMutation();
    const [readMessage] = chatAPI.useReadMessageMutation();
    const [getChat, {isLoading: chatIsLoading}] = chatAPI.useGetChatMutation();
    
    const [chats, setChats] = useState<IChats[]>([]);
    
    const [cars, setCars] = React.useState<null | any[]>(null);
    const [userInfo, setUserInfo] = React.useState<IUserInfo | null>(null);
    const [activeChat, setActiveChat] = React.useState<IChats | undefined>();

    const page = useRef(1);
    const activeChatRef = useRef();

    const gettingChatsInterval = useRef<NodeJS.Timer>() // eslint-disable-line
    const gettingActiveChatInterval = useRef<NodeJS.Timer>() // eslint-disable-line

    const refetchChats = useCallback(() => {
        page.current += 1;
        fetchChats();
    }, [])

    const fetchDialog = useCallback(async (id: string) => {
        const dialog = await getChat(id).unwrap();
        const dialogMessages = dialog.messages.slice();

        dialogMessages.sort((a, b) => {
            const aDate = new Date(a.createdAt).getTime();
            const bDate = new Date(b.createdAt).getTime()
      
            return aDate - bDate;
        })

        setActiveChat({...dialog, messages: dialogMessages});
        activeChatRef.current = {...dialog, messages: dialogMessages};

        return dialog
    }, [])

    const fetchChats = useCallback( async (targetPage?: number) => {
        const {data: chatData} = await getChats(targetPage ?? page.current).unwrap();

        setChats(prev => {
            if (prev && prev.length ===0) {
                return chatData
            }

            const newIds = chatData.map(({id}) => id);

            const nonEmptyChats = prev.filter(chat => chat.messages.length > 0);
            const filteredChats = nonEmptyChats.filter((chat) => !newIds.includes(chat.id));

            return filteredChats.concat(chatData)
        })
    }, [])

    const updateChats = useCallback(async (messageObj: any, chatId: string) => {
        setChats(prev => {            
            const {id, createdAt, readBy, text, mediaUrl, senderId, chatId} = messageObj;

            return prev.map((chat) => chat.id === messageObj.chatId ? ({...chat, messages: [{
                id, createdAt, readBy, text, mediaUrl, senderId, chatId
            }]}) : chat)
        })

        if (chatId === activeChat?.id) {
            setActiveChat(prev => prev ? {...prev, messages: [...prev.messages, messageObj]} : undefined)
        }
    }, [activeChat])

    useEffect(() => {
        fetchChats();

        socket.current = io(process.env.REACT_APP_ADMIN, {
            timeout: 30000,
            query: {
                email: user.email,
                id: user.id,
                profileUrl: user.avatar,
                name: user.initials,
                isSupport: true,
            },
        });

        socket.current.on("disconnect", (reason: string) => {
            toast.error('Соединение с чатом разорвано ❌')
            if (reason === 'io client disconnect') {
                socket.current.connect();
            }
        });

        socket.current.on("connect", () => {
            toast.success('Соединение с чатом активно ✅')
        });

        socket.current.on('connect_error', () => {
            toast.error('Соединение с чатом не установлено ❌')
          });

        socket.current.on('supportRecMessage', (message: any) => {
            const {chatId, sender, isSupport, senderId} = message; 

            const isSupportMessage = isSupport ?? sender?.isSupport;

            updateChats(message, activeChatRef.current?.id);

            showToast();
            setTimeout(() => {
                fetchChats();
            }, 100)
            
        })

        socket.current.on("reconnect", (attempt: any) => {
            toast.success('Связь с чатом восстановлена ✅')
        });

        socket.current.on('reconnect_failed', () => {
            toast.info('Не удалось восстановить связь с чатом 🔄')
          });

        return () => {
            clearInterval(gettingChatsInterval.current)
            gettingChatsInterval.current = undefined;
            socket.current.close();
        }
    }, [])

    const getInfo = async (item: any) => {
        const info = await getUser(item).unwrap();

        setUserInfo(info?.data);
    };

    const selectChat = async (item: IChats) => {
        const dialog = await fetchDialog(item.id);

        setChats(prev => prev.map((chat) => {
            const message = chat.messages[0];

            if (chat.id === item.id) {
                return {...chat, messages: [{...message, readBy: [...message.readBy, Number(user.id)]}]}
            }

            return chat
        }))

        setUserInfo(null);

        const userData = await getUserInfo(item.creator.id).unwrap();

        if (userData.data.cars) {
            setCars(userData.data?.cars);
        }

        joinToChat({
            participant: {
                name: user.initials,
                profilePhotoUrl: user.avatar === null ? "" : user.avatar,
                id: Number(user.id),
            },
            chatId: item.id,
        });
        

        getInfo(dialog?.creatorId);
        
        await readMessage({
            participantId: Number(user.id),
            messageId: item.messages[item.messages.length - 1].id,
        });

    };

    useEffect(() => {
        scrollToBottom();
    }, [activeChat])

    return {
        activeChat,
        setActiveChat,
        userInfo,
        userIsFetching,
        cars,
        selectChat,
        chatsLoading,
        chats,
        refetchChats,
        chatsFetching,
        fetchDialog,
        updateChats
    };
};

export default useNewChatService;
