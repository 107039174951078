import React, { useEffect, useMemo, useState, useRef } from "react";
import { useHistory } from "react-router";
import Select from "react-select";
import { Input } from "reactstrap";

import { formatDate } from "common/dataFormatters";
import { rentCarAPI, rentUserAPI, rentFineAPI } from "services";
import Pagination from "components/Common/Pagination";
import Loader from "components/Common/Loader";

const tableTitles = [
    "ФИО",
    "Вид штрафа",
    "Авто",
    "Адрес",
    "Дата постановления",
    "Сумма",
    "Номер постановления",
    "Дата штрафа",
    "Статус",
    "",
];

const Fines = () => {
    const history = useHistory();
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedCar, setSelectedCar] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [numPost, setNumPost] = useState("");
    const [isCheckedIs1C, setIsCheckedIs1C] = useState(false);

    const { data: cars, isLoading: carsLoading } =
        rentCarAPI.useGetRentCarsQuery({});

    const [
        triggerGetFines,
        { data: fines, isLoading: finesIsLoading, isFetching: finesIsFetching },
    ] = rentFineAPI.useLazyGetFinesQuery();

    useEffect(() => {
        if (isCheckedIs1C === false) {
            triggerGetFines({
                page: currentPage,
                per_page: 10,
                "filters[car_id]": selectedCar?.value ? selectedCar.value : "",
                "filters[num_post]": numPost || "",
            });
        } else {
            triggerGetFines({
                page: currentPage,
                per_page: 10,
                "filters[car_id]": selectedCar?.value ? selectedCar.value : "",
                "filters[num_post]": numPost || "",
                "filters[notSend]": 1,
            });
        }
    }, [isCheckedIs1C, numPost, currentPage, selectedCar]);

    const selectCarOptions = useMemo(
        () =>
            cars?.data.map((car) => ({
                value: car.id,
                label: [car.brand, car.model, car.registration_number].join(
                    " "
                ),
            })),
        [cars]
    );

    const createLabelCar = (car, vehicle_model) => {
        if (car.id != null) {
            return car.brand + " " + car.model + " " + car.registration_number;
        } else {
            return vehicle_model;
        }
    };

    const handleCheckboxChange = (e) => {
        setIsCheckedIs1C(e.target.checked);
    };

    if (finesIsFetching || finesIsFetching || carsLoading) return <Loader />;
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="card card-fines-page">
                        <div style={{ backgroundColor: "white" }}>
                            <div className="header-fines-page">
                                {/* <div className="mr-4" style={{ width: "30%", marginRight: 20 }}>
                                    <Select
                                        onChange={(data) => setSelectedUser(data?.value)}
                                        placeholder="Выбрать пользователя"
                                        isClearable
                                        isSearchable
                                        options={selectUserOptions}
                                    />
                                </div> */}
                                <div className="custom-select">
                                    <Select
                                        onChange={(data) =>
                                            setSelectedCar(data)
                                        }
                                        placeholder={
                                            selectedCar != null
                                                ? selectedCar.label
                                                : "Выбрать авто"
                                        }
                                        isClearable
                                        isSearchable
                                        options={selectCarOptions}
                                    />
                                </div>
                                <div className="mr-2 box-input">
                                    <Input
                                        id="phone"
                                        name="phone"
                                        type="text"
                                        style={{ height: 40 }}
                                        placeholder={numPost ? numPost : "Номер постановления"}
                                        value={numPost}
                                        onChange={(e) => {
                                            setNumPost(e.target.value);
                                        }}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "0 4px",
                                        marginLeft: "0.5rem",
                                    }}
                                >
                                    <div style={{ marginRight: "4px" }}>
                                        Нет в 1С
                                    </div>
                                    <div style={{ marginTop: "0.2rem" }}>
                                        <input
                                            type="checkbox"
                                            checked={isCheckedIs1C}
                                            onChange={handleCheckboxChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                id="table"
                                className="card-header list-title"
                                style={{
                                    backgroundColor: "white",
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                }}
                            >
                                <table className="table">
                                    <thead>
                                        <tr>
                                            {tableTitles.map((title, i) => (
                                                <th scope="col" key={i}>
                                                    {title}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fines?.data?.map(
                                            ({
                                                id,
                                                ko_ap_text,
                                                car,
                                                division_address,
                                                date_post,
                                                vehicle_model,
                                                summa,
                                                date_decis,
                                                num_post,
                                                isPaid,
                                                documentRealizationGUID,
                                                user,
                                            }) => {
                                                if (!user) {
                                                    user = {
                                                        surname:
                                                            "Информации нет",
                                                        name: "",
                                                        patronymic: "",
                                                    };
                                                }
                                                const fine = (
                                                    <tr key={id}>
                                                        <td>
                                                            <span>{`${user.surname} ${user.name} ${user.patronymic}`}</span>
                                                        </td>
                                                        <th>{ko_ap_text}</th>
                                                        <td>
                                                            <span>
                                                                {createLabelCar(
                                                                    car,
                                                                    vehicle_model
                                                                )}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            {division_address}
                                                        </td>
                                                        <td>
                                                            {formatDate(
                                                                date_post,
                                                                "dd-MM-yyyy"
                                                            )}
                                                        </td>
                                                        <td>{summa}</td>
                                                        <td>{num_post}</td>
                                                        <td>
                                                            {formatDate(
                                                                date_decis,
                                                                "dd-MM-yyyy"
                                                            )}
                                                        </td>
                                                        <td>
                                                            {isPaid ? (
                                                                <span className="text-success">
                                                                    Оплачен
                                                                </span>
                                                            ) : (
                                                                <span className="text-danger">
                                                                    Не оплачен
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <button
                                                                    onClick={() =>
                                                                        history.push(
                                                                            `/alyans-rent/fines/${id}`
                                                                        )
                                                                    }
                                                                    style={{
                                                                        border: "none",
                                                                        borderRadius:
                                                                            "5px",
                                                                        padding:
                                                                            "0.7rem",
                                                                        fontWeight:
                                                                            "400",
                                                                    }}
                                                                >
                                                                    Перейти
                                                                </button>
                                                                {documentRealizationGUID ===
                                                                    null && (
                                                                    <div
                                                                        style={{
                                                                            textAlign:
                                                                                "center",
                                                                            marginTop:
                                                                                "0.1rem",
                                                                            color: "#b35b62",
                                                                        }}
                                                                    >
                                                                        Нет в 1С
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                                if (
                                                    isCheckedIs1C === true &&
                                                    documentRealizationGUID ===
                                                        null
                                                )
                                                    return fine;
                                                else if (
                                                    isCheckedIs1C === false
                                                )
                                                    return fine;
                                                else return null;
                                            }
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            {
                                <Pagination
                                    pagesAmount={fines?.meta?.last_page}
                                    currentPage={currentPage}
                                    setCurrentPage={(page) =>
                                        setCurrentPage(page)
                                    }
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Fines;
