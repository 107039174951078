import React, { useState, useEffect, useRef, useCallback } from "react";
import { chatAPI } from "../../../../services/chatService";
import PaginationTable from "components/Common/PaginationTable";
import { formatCarDataForTable } from "common/dataFormatters";
import { useHistory } from "react-router-dom";
import NewChatItem from "./New/ChatItem";
import useNewChatService from "hooks/useNewChatService";
import ActiveChat from "./New/ActiveChat";
import HeaderUserChat from "./HeaderUserChat";
import * as C from "./styles";
import { IChats } from "types/ChatTypes";
import InputChat from "./IputChat";
import audioMessage from "../../../../assets/sounds/chatMessage.mp3";
import { toast } from "react-toastify";
import Loader from "components/Common/Loader";

const WebChat = () => {
  const user = React.useMemo(() => {
    return JSON.parse(localStorage.getItem("user") as string);
  }, []);
  const history = useHistory();
  const [userImage, setUserImage] = useState("");
  const [userName, setUsername] = useState("Admin");
  const activeChatRef = React.useRef<HTMLDivElement | null>(null);
  const [value, setValue] = useState<string>("");
  const [files, setFiles] = useState<any>([]);
  const sound = new Audio(audioMessage);
  const [sendMessage] = chatAPI.useSendMessageMutation();

  const {
    activeChat,
    userInfo,
    cars,
    selectChat,
    chatsLoading,
    chats,
    refetchChats,
    chatsFetching,
    userIsFetching,
    fetchDialog,
    updateChats
  } = useNewChatService({
    scrollToBottom,
    user,
    showToast,
  });

  const chatsContainer = useRef<HTMLDivElement>(null);

  const commonChats = React.useMemo(() => {
    let chatsList: any = [];

    chats?.map((item) => {
      chatsList.unshift({
        ...item,
        isNew: true,
        createdAt: item.updatedAt,
      });
    });

    chatsList.sort((a, b) => {
      return new Date(b.createdAt as string) - new Date(a.createdAt as string);
    });

    return chatsList;
  }, [chats]);

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 400);
  }, [commonChats]);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("user") || "");
        setUsername(obj.initials);
        setUserImage(obj.avatar);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("user") || "");
        setUsername(obj.initials);
        setUserImage(obj.avatar);
      }
    }
  }, []);

  function showToast() {
    toast.info("Пришло сообщение в чат");
    sound.play();
  }

  function scrollToBottom() {
    setTimeout(() => {
    if (activeChatRef.current) {
        activeChatRef.current.scrollTop = activeChatRef.current.scrollHeight;
      }
    }, 100)
  }

  const handleChange = (target: any) => {
    setValue(target.value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (files.length > 0 || value !== "") {
      const formData = new FormData();
      formData.append("from", user.id);
      formData.append("to", activeChat?.id as string);
      formData.append("image", files[0]);
      formData.append("message", value);
      await sendMessage(formData);

      fetchDialog(String(activeChat?.id))

      const listenerId = activeChat?.messages.find((msg) => msg.senderId === user.id)?.sender.listenerId

      const messageObj = {
        id: `id-${value}`,
        text: value,
        mediaUrl: null,
        createdAt:	new Date().toISOString(),
        chatId: activeChat?.id,
        senderId: user.id,
        readBy: [ user.id ],
        sender: { 
          id: user.id, 
          name: user.surname, 
          profilePhotoUrl: user.avatar,
          number: user.phone,
          counterpartyNumber:  null,
          isSupport: true,
          deviceTokens: [],
          listenerId: listenerId,
        }
      }

      updateChats(messageObj, activeChat?.id)
    }
  };

  const handleFileChange = (e: any) => {
    e.target.files.length > 0 && setFiles(e.target.files);
  };

  const handleScroll = useCallback(() => {
    if (!chatsContainer.current) return;

    const { scrollTop, scrollHeight, clientHeight } = chatsContainer.current;

    if (scrollHeight - scrollTop - clientHeight < 200 && !chatsFetching) {
      refetchChats();
    }
  }, [chatsFetching]);

  if (chatsLoading) return <Loader />;
  return (
    <div className="page-content">
      <C.Container>
        <C.PreviewChatsContainer>
          <C.HeaderContainer>
            <C.Avatar
              src={
                userImage
                  ? userImage
                  : "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg"
              }
              alt="userImage"
            />
            <p>{userName}</p>
          </C.HeaderContainer>

          <C.PreviewChatsListContainer>
            <div className="chats" ref={chatsContainer} onScroll={handleScroll}>
              {commonChats?.map((item, index) => {
                return item?.messages?.length !== 0 ? (
                  <NewChatItem
                    selectChat={(item) => {
                      selectChat(item);
                    }}
                    item={item}
                    key={index.toString()}
                    user={user}
                  />
                ) : (
                  <React.Fragment key={index.toString()}></React.Fragment>
                );
              })}
            </div>
          </C.PreviewChatsListContainer>
        </C.PreviewChatsContainer>

        {!chatsLoading && (
          <C.ChatViewContainer>
            {activeChat ? (
              <>
                <HeaderUserChat
                  activeChat={activeChat}
                  userInfo={userInfo}
                  chatsFetching={userIsFetching}
                />

                <C.MessagesContainer>
                  <div className="messages" ref={activeChatRef}>
                    <ActiveChat
                      activeChat={activeChat as IChats}
                      user={user}
                      scrollToBottom={scrollToBottom}
                    />
                  </div>
                  <InputChat
                    value={value}
                    setValue={setValue}
                    files={files}
                    setFiles={setFiles}
                    handleSubmit={handleSubmit}
                    handleFileChange={handleFileChange}
                    handleChange={handleChange}
                  />
                </C.MessagesContainer>
              </>
            ) : null}
          </C.ChatViewContainer>
        )}
        {chatsLoading && <Loader />}
      </C.Container>

      <div className="container-fluid">
        <div>
          {
            <PaginationTable
              title="Список автомобилей пользователя"
              loading={false}
              thTitles={[
                "ID",
                "Марка",
                "Модель",
                "Модификация",
                "Пробег",
                "Номер",
                "Год",
                "STS",
                "VIN",
              ]}
              tableData={formatCarDataForTable(cars)}
              onRowClick={(carId: number) => history.push(`/cars/${carId}`)}
            />
          }
        </div>
      </div>
    </div>
  );
};

export default WebChat;
